<template>
    <div>
        <div class="text-center iq-title-box iq-box iq-title-default">
            <h2 class="iq-title">
                Interested? Let us now!
            </h2>
            <p class="iq-title-desc">If you are interested in applying for a Next Level internship, please complete and submit the form below. If you have questions or concerns, send an email to internship@nlse.com </p>
        </div>
        <div class="wrapper">
      <form action="" @submit.prevent="sendEmail" class="wpcf7-form init" novalidate="novalidate" data-status="init">
        <div class="contact-form contact-style">
            <div class="row">
                <input type="hidden" name="to_email" value="" size="40">
                <input type="hidden" name="cc_email" value="" size="40">
                <div class="col-md-12 cfield">
                    <input type="text" name="name" v-model="name" value="" size="40" aria-required="true" aria-invalid="false" placeholder="Your Name">
                </div>
                <!-- <div class="col-md-6 cfield">
                    <input type="tel" name="phone" v-model="phone" value="" size="40" maxlength="140" minlength="10" aria-required="true" aria-invalid="false" placeholder="Phone Number">
                </div> -->
                <div class="col-md-12 cfield">
                    <input type="email" name="email" v-model="email" value="" size="40" aria-required="true" aria-invalid="false" placeholder="Your Email">
                </div>
				<!-- <div class="col-md-12 cfield">
                    <input type="text" name="major" v-model="major" value="" size="40" aria-required="true" aria-invalid="false" placeholder="Your Major">
                </div> -->
				<div class="col-md-12 cfield">
                    <textarea name="opportunity" v-model="opportunity" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Which Opportunity Area Interests you?"></textarea>
                </div>
                <div class="col-md-12 cfield">
                    <textarea name="reason" v-model="reason" cols="40" rows="10" aria-required="true" aria-invalid="false" placeholder="Why do you want to intern for Next Level?"></textarea>
                </div>
                <div class="col-md-12 cfield">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                    </ul>
                </p>
                </div>
                <div class="col-md-12 cfield text-center">
                    <button id="submit" name="submit" type="submit" value="Send" class="btn btn-hover iq-button">
                        <span class="iq-btn-text-holder">Send Message</span>
                        <span class="iq-btn-icon-holder">
                            <i aria-hidden="true" class="ion ion-plus"></i>
                        </span>
                        <br>
                    </button>
                </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>

import emailjs from 'emailjs-com'

export default {
  name: 'ContactForm',
  props: {
    contactUsData: Object
  },
  data () {
    return {
      name: '',
     // phone: '',
      email: '',
      // major: '',
	  opportunity: '',
	  reason: '',
      errors: []
    }
  },
  methods: {
    sendEmail (e) {
      this.errors = []
      if (!this.name) this.errors.push('Name required.')
      if (!this.email) this.errors.push('Email required.')
      if (!this.opportunity) this.errors.push('Message required.')
      if (this.name && this.email) {
        try {
          emailjs.sendForm('service_888e61q', 'template_xdpzw2v', e.target, 'user_pOxKObRIUjM12T65F08qW')
        } catch (error) {
          console.log({ error })
        }
        // Reset form field
        this.name = ''
        this.email = ''
        // this.phone = ''
        // this.major = ''
		this.opportunity = ''
		this.reason = ''
      }
    }
  }
}
</script>
