<template>
  <main id="main" class="site-main">
    <!--<InternshipNLSE />-->
    <div class=""
      :style="`background: url(${require('@/assets/images/newInternship.png')}) left 38% / cover no-repeat; width: 100%; min-height: calc(125vh - 40px); position: relative; z-index: 1; display: inline-block;`">
      <div class="position-relative h-100 container-fluid">
        <div class="h-100">
          <h1 data-animation-in="fadeInLeft" data-delay-in="0.3"
            class="slider-text  title text-uppercase fadeInLeft animated pt-4 text-primary text-center"
            style="opacity: 1; animation-delay: 0.3s; font-size: 5em;"><b>NEXT Internship Program</b></h1>
          <div class="row align-items-center h-100">
            <div class="pl-5 pt-5 col-md-12 col-lg-12 col-xl-6" style="margin-top:3em; margin-left:7%">
              <h2 data-animation-in="fadeInLeft" data-delay-in="0.3"
                class="slider-text text-primary title text-uppercase fadeInLeft animated pt-4 text-left"
                style="opacity: 1; animation-delay: 0.3s;"><b>Join us <br />
                Be an innovator <br />
                Build your future</b>
              </h2>
              <div data-wp_object-in="fadeInUp" data-delay-in="1.0" class="trending-list">
                <p data-animation-in="fadeInUp" data-delay-in="1.0" class="starring fadeInUp animated mt-5"
                  style="opacity: 1; animation-delay: 1s;">
                  Fall 2022: September 1 – November 30, 2022<br />
                  Spring 2023 : January 16 – April 14, 2023<br />
                  Summer 2023 : May 16 – August 15, 2023
                </p>
              </div>
              <a :href="pdf" target="_blank"><div class="icon-box mt-5 row">
                  <div class="col-md-1"><img :src="download" style="width:3em" alt="download"></div>
                  <div class="col-md-11"><p style="font-size:1em;margin-left:0.4em;padding-top:0.3em;color:white"><b>Download Program Information</b></p></div>
              </div></a>
              <a href='mailto:internship@nlse.com'><div class="icon-box row">
                  <div class="col-md-1"><img :src="mail" style="width:3em" alt="mail"></div>
                  <div class="col-md-11"><p style="font-size:1em;margin-left:0.4em;padding-top:0.3em;color:white"><b>internship@nlse.com</b></p></div>
              </div> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <contact-form />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// import InternshipNLSE from './Components/Projects/InternshipNLSE.vue'
import ContactForm from './Contact/ContactFormInternship.vue'

export default {
  name: 'Internship',
  components: {
    // InternshipNLSE,
    ContactForm
  },
  props: {
    newInternshipImg: { type: String, default: require('../../assets/images/newInternship.png') },
    mail: { type: String, default: require('../../assets/images/mail.png') },
    download: { type: String, default: require('../../assets/images/download.png') },
    pdf: { type: String, default: require('../../assets/pdf/NIP_Brochure_04042022.pdf').default }
  },
}
</script>
